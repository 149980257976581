import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  ContainerBody: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#E8E8E8",
  },

  box: {
    width: 1400,
    alignItems: "center",
    backgroundColor: "#FFF",
  },

  box_mobile: {
    width: 370,
    alignItems: "center",
    backgroundColor: "#FFF",
  },

  title: {
    color: "#000",
    fontSize: 42,
    fontWeight: "bold",
    marginTop: 80,
    textTransform: "uppercase",
  },

  title_mobile: {
    color: "#000",
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 30,
    marginBottom: 20,
    textTransform: "uppercase",
    marginLeft: 20,
    marginRight: 20,
  },

  text: {
    color: "#000",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },

  text_mobile: {
    color: "#000",
    fontSize: 14.7,
    fontWeight: "bold",
    marginTop: 10,
    textAlign: "left",
  },

  text_mobile_2: {
    color: "#000",
    fontSize: 14.7,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 20,
    textAlign: "left",
  },

  divBottom: {
    width: "40%",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: 20,
    flexWrap: "wrap",
  },

  bottom: {
    backgroundColor: "#5BC51B",
    width: 250,
    height: 60,
    margin: 15,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
  },

  textBottom: {
    fontSize: 17,
    fontWeight: "700",
    textTransform: "uppercase",
  },

  input: {
    borderWidth: 0,
    borderRadius: 15,
    padding: 15,
    backgroundColor: "#E8E8E8",
    width: 455,
    margin: 20,
  },

  input_mobile: {
    borderWidth: 0,
    borderRadius: 15,
    padding: 15,
    backgroundColor: "#E8E8E8",
    width: 340,
    margin: 10,
  },

  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -9, height: 8 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },

  shadowProp_mobile: {
    shadowColor: "#171717",
    shadowOffset: { width: -5, height: 8 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  bottomEnviar: {
    backgroundColor: "#5BC51B",
    width: 250,
    height: 60,
    marginTop: 20,
    marginBottom: 90,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
  },

  bottomAdd: {
    backgroundColor: "transparent",
    width: 455,
    height: 50,
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: "#000",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },

  bottomAdd_mobile: {
    backgroundColor: "transparent",
    width: 340,
    height: 50,
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: "#000",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },

  divButoomAdd: {
    margin: 15,
    width: 455,
  },

  divButoomAdd_mobile: {
    margin: 15,
    width: 340,
  },

  textBottomAdd: {
    fontSize: 14,
    fontWeight: "700",
    textTransform: "uppercase",
  },

  shadowProp2: {
    shadowColor: "#171717",
    shadowOffset: { width: -1, height: 12 },
    shadowOpacity: 0.3,
    shadowRadius: 9,
  },

  upload: {
    marginTop: 20,
    width: 250,
    height: 50,
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: "#700DFA",
    justifyContent: "center",
    alignItems: "center",
  },

  textUpload: {
    fontSize: 17,
    fontWeight: "700",
    textAlign: "center",
  },

  textLink: {
    color: "#EB3100",
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
    textDecorationLine: "underline",
  },

  containerAnimation: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  animation: {
    width: 100,
    height: 100,
    marginBottom: 20,
    alignItems: "center",
    margin: 50,
    marginBottom: 90,
  },

  animationEnviado: {
    width: 100,
    height: 100,
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
  },

  titleAnimationEnviado: {
    fontSize: 37,
    color: "#000",
    textAlign: "center",
  },

  textAnimationEnviado: {
    fontSize: 25,
    color: "#000",
    textAlign: "center",
    margin: 20,
  },

  text2AnimationEnviado: {
    fontSize: 25,
    color: "#000",
    textAlign: "center",
    marginBottom: 90,
    marginLeft: 20,
    marginRight: 20,
  },
});

export default styles;
